import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VisualSearchEngine = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchImages = async (query = 'random') => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://api.unsplash.com/photos/random`, {
        params: { count: 9, query },
        headers: {
          Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
        }
      });
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const analyzeImage = async (description) => {
    try {
      const response = await axios.post(
        'https://api-inference.huggingface.co/models/gpt2',
        {
          inputs: `Analyze this image description: ${description}. Provide a detailed description and suggest related search terms.`,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_HUGGINGFACE_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data[0].generated_text;
    } catch (error) {
      console.error("Error analyzing image:", error);
      return description;
    }
  };

  const handleImageClick = async (image) => {
    setSelectedImage(image);
    setIsLoading(true);
    
    const analysis = await analyzeImage(image.alt_description || image.description);
    
    console.log("Image analysis:", analysis);
    
    // Extract a suitable search query from the analysis
    const searchQuery = analysis.split('.')[0]; // Use the first sentence as a query
    
    fetchImages(searchQuery);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">AI-Enhanced Visual Search Engine</h1>
      
      {selectedImage && (
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Selected Image:</h2>
          <img 
            src={selectedImage.urls.small} 
            alt={selectedImage.alt_description} 
            className="w-32 h-32 object-cover rounded"
          />
        </div>
      )}
      
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid grid-cols-3 gap-4">
          {images.map((image) => (
            <div 
              key={image.id} 
              className="cursor-pointer hover:opacity-75 transition-opacity"
              onClick={() => handleImageClick(image)}
            >
              <img 
                src={image.urls.small} 
                alt={image.alt_description} 
                className="w-full h-48 object-cover rounded"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VisualSearchEngine;